<div class="side-bar__action-bar theme__background--primary">
  <a class="side-bar__link" data-e2e="RWYCIAKRGJ"> <i class="ui-account"></i>{{ stateService.user?.displayName }} </a>
  <button
    class="side-bar__close"
    data-e2e="RWYCIAGM90"
    (click)="stateService.mobileMenuOpen = false"
    [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Mobile menu close', 'textlink')"
  >
    {{ 'sideBar__close' | translate }}
  </button>
</div>

<grip-role></grip-role>

<ul class="side-bar__menu">
  <li *ngFor="let item of menu; let i = index">
    <a
      *ngIf="isExternalLink(item)"
      [class.side-bar__link--collapsed]="isContainer(item) && !isContainerOpen(item)"
      [class.side-bar__link--expanded]="isContainer(item) && isContainerOpen(item)"
      [class.side-bar__link--active]="isActive(item)"
      [class.theme__color--primary]="isActive(item)"
      class="side-bar__link theme-hover__color--primary"
      target="_blank"
      [href]="item.url"
      [attr.data-track]="digitalDataService.getDataTrackAttribute('nav', 'body', item.title, 'menu')"
      [attr.data-e2e]="'RWYCIABO9S-' + i"
    >
      <i *ngIf="item.icon" class="{{ item.icon }}"></i>
      {{ item.title }} <i class="ui-external-link side-bar__menu-icon--external"></i>
      <span *ngIf="item.addon" class="side-bar__link-addon theme__background--primary">{{ item.addon }}</span>
    </a>
    <span
      *ngIf="!isExternalLink(item)"
      [class.side-bar__link--collapsed]="isContainer(item) && !isContainerOpen(item)"
      [class.side-bar__link--expanded]="isContainer(item) && isContainerOpen(item)"
      [class.side-bar__link--active]="isActive(item)"
      [class.theme__color--primary]="isActive(item)"
      (click)="goToItem(item)"
      class="side-bar__link theme-hover__color--primary"
      [attr.data-track]="digitalDataService.getDataTrackAttribute('nav', 'body', item.title, 'menu')"
    >
      <i *ngIf="item.icon" class="{{ item.icon }}"></i>
      {{ item.title }}
      <span *ngIf="item.addon" class="side-bar__link-addon theme__background--primary">{{ item.addon }}</span>
    </span>
    <ul *ngIf="isContainer(item)" [class.side-bar__sub-menu--visible]="isContainerOpen(item)" class="side-bar__sub-menu">
      <li *ngFor="let subItem of getChildren(item); let i = index">
        <a
          *ngIf="isExternalLink(subItem)"
          class="side-bar__link theme-hover__color--primary"
          target="_blank"
          [href]="subItem.url"
          [attr.data-track]="digitalDataService.getDataTrackAttribute('nav', 'body', subItem.title, 'menu')"
          [attr.data-e2e]="'RWYCIAG0KR-' + i"
        >
          {{ subItem.title }} <i class="ui-external-link side-bar__menu-icon--external"></i>
          <span *ngIf="subItem.addon" class="side-bar__link-addon theme__background--primary">{{ subItem.addon }}</span>
        </a>
        <a
          *ngIf="!isExternalLink(subItem)"
          [class.side-bar__link--active]="isActive(subItem)"
          class="side-bar__link theme-hover__color--primary"
          (click)="goToItem(subItem)"
          [attr.data-track]="digitalDataService.getDataTrackAttribute('nav', 'body', subItem.title, 'menu')"
          [attr.data-e2e]="'RWYCIA1B6V-' + i"
        >
          {{ subItem.title }}
          <span *ngIf="subItem.addon" class="side-bar__link-addon theme__background--primary">{{ subItem.addon }}</span>
        </a>
      </li>
    </ul>
  </li>
</ul>

<div class="side-bar__mobile-options margin-top-1 hidden-m hidden-l">
  <a
    class="button button--secondary button--fluid"
    data-e2e="RWYCIAI5V7"
    (click)="addAnotherAccount()"
    [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Account', 'Add another account')"
  >
    {{ 'profile__addAnotherAccount' | translate }}
  </a>
  <a
    class="button button--secondary button--fluid margin-top-2"
    data-e2e="RWYCIADSTN"
    (click)="goToProfile()"
    [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Profile', 'profile & help')"
  >
    {{ 'profile__link' | translate }}
  </a>
  <a
    data-e2e="RWYCIA3GVP"
    *ngIf="!!stateService.portalSettings?.portal?.passwordResetUrl"
    class="button button--secondary button--fluid margin-top-2"
    (click)="forgotPassword()"
    target="_blank"
    [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Change password', 'profile & help')"
  >
    {{ 'profile__changePassword' | translate }}
  </a>
  <a
    data-e2e="RWYCIAGP61"
    *ngIf="!!menuDataService.logoutUrl"
    class="button button--secondary button--fluid margin-top-2"
    href="{{ menuDataService.logoutUrl }}"
    [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Log out', 'profile & help')"
  >
    {{ 'profile__logout' | translate }}
  </a>
</div>
