<div *ngIf="options?.length && options?.length > 1 && activeOption" class="role">
  <button
    data-e2e="RWYCIA721N"
    class="role-button"
    [class.role-button__dropdown-open]="dropdownOpen"
    (click)="dropdownOpen = !dropdownOpen"
    click-outside
    (clickOutside)="dropdownOpen ? (dropdownOpen = false) : null"
    [attr.data-track]="digitalDataService.getDataTrackAttribute('nav', 'body', 'Role selector', 'menu')"
  >
    <div class="role-button__content">
      <img class="role-button__logo" [gripSrc]="activeOption.icon" alt="Logo: {{ activeOption.label }}" />
      <span class="role-button__label">{{ activeOption.label }}</span>
      <i class="role-button__icon" [class.ui-caret-down]="!dropdownOpen" [class.ui-caret-up]="dropdownOpen"></i>
    </div>
  </button>

  <div *ngIf="dropdownOpen" class="role-dropdown">
    <ul class="role-dropdown-menu">
      <li *ngFor="let role of options; let i = index" class="role-dropdown-menu__item">
        <button (click)="selectOption(role)" class="role-dropdown-menu__button" [attr.data-e2e]="'RWYCIATJAH-' + i" type="button">
          <img class="role-dropdown-menu__logo" [gripSrc]="role.icon" alt="Logo: {{ role.label }}" />
          <span class="role-dropdown-menu__label">{{ role.label }}</span>
        </button>
      </li>
    </ul>
  </div>
</div>
