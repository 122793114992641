<header class="app-layout__top-bar top-bar">
  <a
    data-e2e="RWYCIAIFPV"
    routerLink="/"
    class="top-bar__logo"
    [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Home escape hatch', 'textlink')"
  >
    <img src="{{ stateService.tenant?.logo }}" alt="{{ stateService.tenant?.name }}" />
  </a>
  <span class="top-bar__title theme__color--primary">{{ stateService.tenant?.name }}</span>

  <ul class="top-bar__menu">
    <li class="top-bar__item" *ngIf="showProvisioning && !showErrorProvisioning">
      <a
        data-e2e="RWYCIAAL8T"
        class="top-bar__link provisioning-button"
        [class.provisioning-button--active]="doProvisioningAnimation"
        [class.top-bar__link--active]="provisioningOpen"
        (click)="provisioningOpen = !provisioningOpen"
        [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Provisioning popup', 'profile & help')"
      >
        <i class="ui-renew provisioning-button__icon"></i>
        <div class="progress-bar provisioning-button__progress">
          <div class="progress-bar__meter">
            <div
              class="progress-bar__value theme__background--primary"
              [style.width]="provisioningProgressPercentage.toString() + '%'"
            ></div>
          </div>
        </div>
      </a>
      <grip-provisioning-queue *ngIf="provisioningOpen" (closeSelf)="provisioningOpen = false"> </grip-provisioning-queue>
    </li>
    <li class="top-bar__item">
      <a
        data-e2e="RWYCIARM41"
        class="top-bar__link"
        href="{{ stateService.portalSettings?.portal?.knowledgebaseUrl }}"
        target="_blank"
        [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Help', 'profile & help')"
      >
        <i class="ui-help"></i>
      </a>
    </li>
    <li
      class="top-bar__item top-bar__item--trigger"
      (click)="stateService.mobileMenuOpen = true"
      [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Mobile menu open', 'textlink')"
    >
      {{ 'menuTrigger__label' | translate }}
    </li>
    <li class="top-bar__item hidden-xs hidden-s">
      <span
        class="top-bar__link"
        [class.top-bar__link--collapsed]="!stateService.profileMenuOpen"
        [class.top-bar__link--expanded]="stateService.profileMenuOpen"
        (click)="stateService.profileMenuOpen = !stateService.profileMenuOpen"
        click-outside
        (clickOutside)="stateService.profileMenuOpen ? (stateService.profileMenuOpen = false) : null"
      >
        <i class="ui-account top-bar__icon--margin"></i>
        {{ stateService.user?.displayName }}
      </span>
      <ul class="top-bar__action-menu" [class.top-bar__action-menu--visible]="stateService.profileMenuOpen">
        <div class="accounts">
          <li *ngFor="let data of currentSessions; let i = index" role="none" (click)="selectUser(data)">
            <a role="menuitem" class="session-item" [attr.data-e2e]="'RWYCIASU0V-' + i">
              <img height="40" [src]="data.tenant.icon" alt="icon" class="item-image" />
              <div class="session-name">
                <div class="mainitem">
                  {{ data.user.name }}
                </div>
                <div class="subitem">{{ data.tenant.shortName }}</div>
              </div>
            </a>
          </li>
        </div>
        <li class="menu-link">
          <a data-e2e="RWYCIA2QTV" class="top-bar__link theme-hover__color--secondary" (click)="addAnotherAccount()">
            {{ 'profile__addAnotherAccount' | translate }}
          </a>
        </li>
        <li class="menu-link">
          <a
            data-e2e="RWYCIA8MCT"
            class="top-bar__link theme-hover__color--secondary"
            (click)="goToProfile()"
            [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Profile', 'profile & help')"
          >
            {{ 'profile__link' | translate }}
          </a>
        </li>
        <li class="menu-link">
          <a
            data-e2e="RWYCIAL8SP"
            class="top-bar__link theme-hover__color--secondary"
            *ngIf="!!stateService.portalSettings?.portal?.passwordResetUrl"
            (click)="forgotPassword()"
            target="_blank"
            [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Change password', 'profile & help')"
          >
            {{ 'profile__changePassword' | translate }}
          </a>
        </li>
        <li class="menu-link">
          <a
            data-e2e="RWYCIA6UGD"
            class="top-bar__link theme-hover__color--secondary"
            *ngIf="!!menuDataService.logoutUrl"
            (click)="logout()"
            [attr.data-track]="digitalDataService.getDataTrackAttribute('user', 'header', 'Log out', 'profile & help')"
          >
            {{ 'profile__logout' | translate }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
</header>
